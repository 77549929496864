<template>
  <v-card>
    <v-card-title>
      Ranking de Resultado - {{ currentDate.mes_ref }}/{{ currentDate.ano_ref }}
    </v-card-title>
    <v-card class="height-card" elevation="0">
      <v-card-text v-if="resultList.length != 0">
        <v-list>
          <template v-for="(item, i) in resultList">
            <v-list-item :key="i">
              <v-list-item-icon>
                <BaseSegmento :segmento="item.id_band" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.apelido }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-icon v-if="hiddenValue">
                {{ item.total_bonus | BrazilianCurrency }}
              </v-list-item-icon>
              <v-list-item-icon class="space-value" v-else> </v-list-item-icon>
            </v-list-item>
            <v-divider :key="`${i}-divider`"></v-divider>
          </template>
        </v-list>
      </v-card-text>
      <v-card-text v-else>
        <p class="text-center">
          Não há dados para serem apresentados
        </p>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import BaseSegmento from "@/components/shared/BaseSegmento";
import currencyMixin from "@/mixins/currencyMixin";

export default {
  mixins: [currencyMixin],
  components: { BaseSegmento },
  props: {
    apuracao: {
      type: Array
    },
    currentDate: {
      ano_ref: {
        type: [Number, String]
      },
      mes_ref: {
        type: [Number, String]
      }
    },
    hiddenValue: {
      type: Boolean
    }
  },
  data() {
    return {
      company: [],
      resultList: []
    };
  },

  methods: {
    groupByCompany(array, key) {
      return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        return result;
      }, {});
    },
    getValues() {
      this.resultList = [];
      Object.keys(this.company).map(item => {
        let res = { id_empresa: item };
        res["total_bonus"] = 0;
        res["cresc_geral"] = 0;
        res["id_band"] = 0;
        res["apelido"] = "";
        this.company[item].map(subitem => {
          res.id_band = subitem.id_band;
          res.apelido = subitem.apelido;
          res.total_bonus += parseFloat(subitem.total_bonus);
          res.cresc_geral += parseFloat(this.checkValue(subitem.cresc_geral));
        });
        res.total_bonus = this.formatValue(res.total_bonus);
        res.cresc_geral = `${res.cresc_geral.toFixed(1)}%`;

        this.resultList.push(res);
      });

      this.resultList = this.resultList.sort((a, b) =>
        a.total_bonus < b.total_bonus ? 1 : -1
      );
    },
    formatValue(value) {
      if (!value) return 0;
      return value;
    },

    checkValue(val) {
      if (!val) return 0;
      val = String(val);
      if (val.startsWith("-.")) return this.insertZeroBeforeDot(val);
      if (val.startsWith(".")) return this.insertZeroBeforeLess(val);
      return val;
    },

    insertZeroBeforeLess(val) {
      return val.replace("-.", "-0.");
    },

    insertZeroBeforeDot(val) {
      return val.replace(".", "0.");
    }
  },

  watch: {
    apuracao(value) {
      this.company = this.groupByCompany(value, "id_empresa");
      this.getValues();
    }
  }
};

//Criar um objeto, onde a key é o nome da empresa. A value, um objeto contendo resultado e crescimento
</script>

<style scoped>
.height-card {
  min-height: 404px !important;
  max-height: 404px !important;
  overflow-y: scroll !important;
}

.space-value {
  background-color: #e4e4e4;
  width: 65px;
  height: 14px;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(15, 42, 73);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 3px;
}

*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(15, 42, 73, 0.3);
  border-radius: 20px;
}
</style>
